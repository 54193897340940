import { Breadcrumb, PageHeader } from "antd";
import Link from "next/link";
import React from "react";
import { Container } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import ConvertToEn from "utils/convertToEn";

function PagesHeaderCustum({ data }) {
  return (
    <div className="pageHeader">
      <Container className="pagecontainer">
        <PageHeader
          className="site-page-header"
          title={data?.subCategori?.text}
          subTitle={
            <>
              <Breadcrumb>
                <Breadcrumb.Item href="">
                  <Link href={`/`}>
                    <a>Ana Sayfa</a>
                  </Link>
                </Breadcrumb.Item>

                {data?.content ? (
                  <>
                    <Breadcrumb.Item href="">
                      <Link
                        href={`${ConvertToEn(data?.kategori.text)}-${
                          data?.kategori?.id
                        }/${ConvertToEn(data?.subCategori?.text)}-${
                          data?.subCategori?.id
                        }`}
                      >
                        <a>{data?.subCategori?.text}</a>
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{data?.content?.title}</Breadcrumb.Item>
                  </>
                ) : (
                  <Breadcrumb.Item>{data?.subCategori?.text}</Breadcrumb.Item>
                )}
              </Breadcrumb>
            </>
          }
        />
      </Container>
    </div>
  );
}

export default PagesHeaderCustum;
