import PagesHeader from "components/site/pagesHeader/pagesHeader";
import { Row, Col, Container } from "react-bootstrap";
import { CenterComponentsContents, GetSideBars } from "../../centerComponent";
import { getData } from "../../utils/fetchData";
import { findEnum } from "../../utils/services";

const Index = ({ cards, isCategory, error = "" }) => {
  const KategoriText = cards?.kategori.text;
  if (!cards) return "Beklenmeyen bir Hata oluştu cards is undefind";
  return (
    <div id="homePage" style={{ minHeight: "80vh", background: "#f9fcff" }}>
      <PagesHeader data={cards} />
      <Container>
        <Row>
          <Col
            xl={isCategory ? 12 : 9}
            lg={isCategory ? 12 : 8}
            md={isCategory ? 12 : 12}
            xs={isCategory ? 12 : 12}
          >
            <CenterComponentsContents isCategory={isCategory} cards={cards} />
          </Col>
          {!isCategory && (
            <Col
              xl={{ span: 3, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              md={{ span: 10, offset: 1 }}
              xs={12}
            >
              <GetSideBars
                data={cards}
                sidebarName={
                  KategoriText == "Haberler" ? "Sidebar1" : "Sidebar2"
                }
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export async function getServerSideProps(context) {
  function isNumeric(num) {
    return !isNaN(num);
  }

  context.res.setHeader(
    "Cache-Control",
    "public, s-maxage=10, stale-while-revalidate=2"
  );
  let data = context.query.kategori;
  let categoryId = data[data.length - 1]?.split("-")[1];
  let contentId = data[data.length - 1]?.split("-")[0];
  let isCategory = isNumeric(categoryId);

  /*
    isCategori true ise o zaman (kategori) ve (subKategori) bilece�iz...
  */

  try {
    // Category Detay ise
    if (isCategory && categoryId) {
      let kategori = await getData(`/Site/GetCategoryTree/${categoryId}`);
      let res = findEnum(kategori.subCategories, categoryId);
      let subCategori = res ?? kategori;

      return {
        props: {
          cards: { kategori, subCategori: subCategori ?? {}, categoryId },
          isCategory,
        },
      };
    } else {
      //Content Detay
      categoryId = data[data.length - 2]?.split("-")[1];
      contentId = data[data.length - 1]?.split("-")[0];
      let kategori = await getData(`/Site/GetCategoryTree/${categoryId}`);
      let content = await getData(`/Site/GetContent/${contentId}`, false);

      let subCategori =
        findEnum(kategori.subCategories, categoryId) ?? kategori;
      return {
        props: {
          cards: {
            kategori,
            categoryId,
            content,
            subCategori: subCategori ?? {},
          },
          isCategory,
        },
      };
    }
  } catch (err) {
    console.log("this is error : ", err);
    return {
      props: {
        error: "Beklenmeyen bir Hata oluştu",
      },
    };
  }
}

export default Index;
